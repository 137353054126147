import "./App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GanekNav from "./Nav";
import Footer from "./Footer";
import {
  ContentHeader,
  Content,
  AppWrapper,
  ModalImage,
} from "./layout/Layout";
import { Highlight } from "./Utils";
import { useRef } from "react";
import { DescriptionBlock } from "./layout/Layout";

export default function Experiments() {
  const vidRef = useRef();

  return (
    <AppWrapper>
      <Content>
        <GanekNav />

        <ContentHeader>EXPERIMENTS</ContentHeader>

        <Container
          className="p-3"
          style={{ color: "white", pointerEvents: "auto" }}
        >
          <Row>
            <Col>
              <DescriptionBlock>
                <Highlight>
                  As a cutting-edge creative laboratory, we experiment with many
                  different real time pipelines and technologies.
                </Highlight>{" "}
                We use game engines like Unreal Engine and Unity, but we also
                create experiences using TouchDesigner, Houdini, motion capture,
                NERFs and Gaussian splatting, and a wide range of sensors like
                the Kinect Azure and Orbbec Femto.
              </DescriptionBlock>
            </Col>
          </Row>

          {/* <Row className="mt-4" style={{ pointerEvents: "none" }}>
            <Col>
              <video ref={vidRef} autoPlay muted loop width={"100%"}>
                <source
                  src="/videos/Ganek-Immersive-Studio-Real-Time-Media-Pipes-Touchdesigner-01.mp4"
                  type="video/mp4"
                />
              </video>
            </Col>
          </Row> */}

          <Row className="mt-4">
            <Col>
              <ModalImage path="/images/experiments/TouchDesigner-PointCloud-1.webp" />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <ModalImage path="/images/experiments/TouchDesigner-PointCloud-2.webp" />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <ModalImage path="/images/experiments/InstantNGP-Nerf-AI.webp" />
            </Col>
          </Row>
        </Container>
      </Content>

      <Footer />
    </AppWrapper>
  );
}
