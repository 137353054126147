import "../App.css";
import GanekNav from "../Nav";
import Footer from "../Footer";
import { Content, AppWrapper, ProjectPage } from "../layout/Layout";
import { useEffect } from "react";

export default function HelpIsOnTheWay() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppWrapper>
      <Content>
        <GanekNav />

        <ProjectPage
          title="HELP IS ON THE WAY"
          creator="Nathan Fairchild"
          tags={["Virtual Reality", "Cinematic Experience"]}
          bannerPath="/images/projects/2023/help-on-way/HelpIsOnTheWay3.webp"
          descriptionImagePath="/images/projects/2023/help-on-way/HelpIsOnTheWay1.webp"
          // videoSrc="https://ganek-media.s3.us-west-1.amazonaws.com/phantoms-ganek-trailer1-1920.mp4"
          primaryImagePaths={[
            "/images/projects/2023/help-on-way/HelpIsOnTheWay2.webp",
            "/images/projects/2023/help-on-way/HelpIsOnTheWay4.webp",
          ]}
        >
          Help Is On the Way is an experiment in building suspense in VR. You
          will accompany Flora Chapman on her way home at night, as she finds
          herself pursued by a strange and persistent presence. When Flora calls
          911, her real challenge begins: communicating her situation to a brand
          new A.I. 911 operator. Experience this frightening night with Flora as
          you shift seamlessly between her viewpoint and that of her pursuer.
        </ProjectPage>
      </Content>

      <Footer />
    </AppWrapper>
  );
}
