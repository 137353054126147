import "./App.css";
import GlassFlower from "./three/GlassFlower";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import Proposals from "./Proposals";
import Experiments from "./Experiments";
import Stories from "./Stories";
import { MobileProvider } from "./layout/MobileProvider";
import { Suspense } from "react";
import TheyShall from "./projects/TheyShallNotComeBack";
import PerfectCity from "./projects/PerfectCity";
import WeBecomeSomethingElse from "./projects/WeBecomeSomethingElse";
import { redirect } from "react-router-dom";
import Phantoms from "./projects/Phantoms";
import HelpIsOnTheWay from "./projects/HelpIsOnTheWay";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <Home />
      </div>
    ),
  },
  {
    path: "/about",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <About />
      </div>
    ),
  },
  {
    path: "/projects",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <Projects />
      </div>
    ),
  },
  {
    path: "/proposals",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <Proposals />
      </div>
    ),
  },
  {
    path: "/experiments",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <Experiments />
      </div>
    ),
  },
  {
    path: "/stories",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <Stories />
      </div>
    ),
  },
  {
    path: "/projects/they-shall-not-come-back-virtual-reality",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <TheyShall />
      </div>
    ),
  },
  {
    path: "/projects/perfect-city-unreal-engine",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <PerfectCity />
      </div>
    ),
  },
  {
    path: "/projects/we-become-something-else-unreal-engine",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <WeBecomeSomethingElse />
      </div>
    ),
  },
  {
    path: "/projects/phantoms-virtual-reality",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <Phantoms />
      </div>
    ),
  },
  {
    path: "/projects/help-is-on-the-way",
    element: (
      <div style={{ height: "200vh" }}>
        <Suspense>
          <GlassFlower />
        </Suspense>
        <HelpIsOnTheWay />
      </div>
    ),
  },
  // REDIRECT ALL BAD PATHS BACK TO HOME.
  {
    path: "*",
    element: (
      <Navigate replace to="/" />
    ),
  },
]);

export default function App() {
  return (
    <MobileProvider>
      <RouterProvider router={router} />
    </MobileProvider>
  );
}
