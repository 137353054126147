import "./App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GanekNav from "./Nav";
import Footer from "./Footer";
import { ContentHeader, Content, AppWrapper } from "./layout/Layout";
import { Highlight } from "./Utils";
import { StoryContainer } from "./layout/Layout";

export default function Stories() {
  return (
    <AppWrapper>
      <Content>
        <GanekNav />

        <ContentHeader>STORIES</ContentHeader>

        <StoryContainer
          className="mt-4 mb-5"
          title="Motion Capture with Professor Esther Chae"
          creator="Nathan Fairchild"
          imagePath="\images\stories\motion-capture-esther\GIS-Ganek-Immersve-Studio-Motion-Capture-Virtual-Realty-2-2048x1152.webp"
        >
          Motion Capture with Professor Esther Chae The Ganek Immersive Studio
          partnered with Professor Esher Chae, School of Dramatic Arts, on an
          ongoing investigation into exploring body movement in Virtual Reality.
          Inspired by the choreography and discipline established by Pina
          Bausch, this initial test was to examine how well subtle motion would
        </StoryContainer>

        <StoryContainer
          className="mt-4 mb-5"
          noBorder={true}
          title="Motion Capture with Professor Esther Chae"
          creator="Nathan Fairchild"
          imagePath="\images\stories\motion-capture-esther\GIS-Ganek-Immersve-Studio-Motion-Capture-Virtual-Realty-2-2048x1152.webp"
        >
          Motion Capture with Professor Esther Chae The Ganek Immersive Studio
          partnered with Professor Esher Chae, School of Dramatic Arts, on an
          ongoing investigation into exploring body movement in Virtual Reality.
          Inspired by the choreography and discipline established by Pina
          Bausch, this initial test was to examine how well subtle motion would
        </StoryContainer>
      </Content>

      <Footer />
    </AppWrapper>
  );
}
