import "./App.css";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useIsMobile } from "./layout/MobileProvider";

export default function GanekNav() {
  const location = useLocation();
  const [hoverLink, setHoverLink] = useState("");
  const { isMobile } = useIsMobile();

  return (
    <div className="mb-4">
      <Row className="mt-2 mb-1">
        <Col className="my-auto">
          <a
            href="https://cinema.usc.edu/"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "black",
              pointerEvents: "auto",
            }}
          >
            <Image
              style={{ width: isMobile() ? "60px" : "200px", height: "100%" }}
              src="/images/logos/sca-logo.png"
            />
          </a>
        </Col>
        <Col className="my-auto text-end">
          <a
            href="https://www.usc.edu/"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "black",
              pointerEvents: "auto",
            }}
          >
            <Image
              style={{ width: isMobile() ? "60px" : "200px", height: "100%" }}
              src="/images/logos/I2L_Mono_Word_RegUse_Card-Wh_RGB.png"
            />
          </a>
        </Col>
      </Row>
      <Navbar
        expand="lg"
        style={{ background: "white", pointerEvents: "auto" }}
      >
        <Container>
          <Navbar.Brand
            as={Link}
            to="/"
            style={{fontSize: isMobile() ? 16 : 20}}
          >
            GANEK IMMERSIVE STUDIO
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/about"
                onMouseEnter={() => setHoverLink("about")}
                onMouseLeave={() => setHoverLink("")}
                style={
                  location.pathname === "/about" || hoverLink == "about"
                    ? { background: "#b1fc05" }
                    : {}
                }
              >
                ABOUT
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/projects"
                onMouseEnter={() => setHoverLink("projects")}
                onMouseLeave={() => setHoverLink("")}
                style={
                  location.pathname === "/projects" || hoverLink == "projects"
                    ? { background: "#b1fc05" }
                    : {}
                }
              >
                PROJECTS
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/proposals"
                onMouseEnter={() => setHoverLink("proposals")}
                onMouseLeave={() => setHoverLink("")}
                style={
                  location.pathname === "/proposals" || hoverLink == "proposals"
                    ? { background: "#b1fc05" }
                    : {}
                }
              >
                PROPOSALS
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/experiments"
                onMouseEnter={() => setHoverLink("experiments")}
                onMouseLeave={() => setHoverLink("")}
                style={
                  location.pathname === "/experiments" || hoverLink == "experiments"
                    ? { background: "#b1fc05" }
                    : {}
                }
              >
                EXPERIMENTS
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                to="/stories"
                onMouseEnter={() => setHoverLink("stories")}
                onMouseLeave={() => setHoverLink("")}
                style={
                  location.pathname === "/stories" || hoverLink == "stories"
                    ? { background: "#b1fc05" }
                    : {}
                }
              >
                STORIES
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
