import "../App.css";
import GanekNav from "../Nav";
import Footer from "../Footer";
import { Content, AppWrapper, ProjectPage } from "../layout/Layout";
import { useEffect } from "react";

export default function WeBecomeSomethingElse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppWrapper>
      <Content>
        <GanekNav />

        <ProjectPage
          title="EMBODIMENT I: We Become Something Else"
          creator="Michael Overton Brown"
          tags={[
            "Virtual Reality",
            "Unreal Engine",
            "Interactive Filmic Experience",
          ]}
          bannerPath="/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-11.webp"
          descriptionImagePath="/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-Square.webp"
          videoSrc="https://ganek-media.s3.us-west-1.amazonaws.com/wbse-ganek-trailer2-1920.mp4"
          primaryImagePaths={[
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-12.webp",
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-8.webp",
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-4.webp",
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-13.webp",
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-14.webp",
          ]}
          subImagePaths={[
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-6.webp",
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-9-Square.webp",
            "/images/projects/2023/we-become/Michael-Overton-Brown-Embodiment1-2.webp",
          ]}
        >
          We Become Something Else is an experimental interactive virtual
          reality animation that explores life, death, rebirth, and the unity of
          all things. The player is taken on a journey through a series of
          abstract, evocative spaces, challenging traditional narrative
          structure by allowing the spaces, architectures, and music to convey
          the emotional narrative of the work.
          
          {/* <div className="mt-4">
            There is simultaneous beauty and horror in the fact that we as
            humans are composed of the same matter as everything else in the
            universe. And because of this fundamental truth, when we are “gone”,
            we are not truly gone: we have merely become something else. This
            work attempts to convey the terrible beauty of that reality.
          </div> */}
        </ProjectPage>
      </Content>

      <Footer />
    </AppWrapper>
  );
}
