import "../App.css";
import GanekNav from "../Nav";
import Footer from "../Footer";
import { Content, AppWrapper, ProjectPage } from "../layout/Layout";
import { useEffect } from "react";

export default function Phantoms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppWrapper>
      <Content>
        <GanekNav />

        <ProjectPage
          title="EMBODIMENT II: PHANTOMS"
          creator="Michael Overton Brown"
          tags={[
            "Virtual Reality",
            "Experimental Embodiment Framework"
          ]}
          bannerPath="/images/projects/2023/phantoms/Michael-Overton-Brown-Phantoms-3.webp"
          descriptionImagePath="/images/projects/2023/phantoms/Michael-Overton-Brown-Phantoms-2.webp"
          videoSrc="https://ganek-media.s3.us-west-1.amazonaws.com/phantoms-ganek-trailer1-1920.mp4"
          primaryImagePaths={[
            "/images/projects/2023/phantoms/Michael-Overton-Brown-Phantoms-1.webp",
            "/images/projects/2023/phantoms/Michael-Overton-Brown-Phantoms-4.webp",
          ]}
          // subImagePaths={[
          //   "/images/projects/2023/phantoms/Michael-Overton-Brown-Embodiment1-6.webp",
          //   "/images/projects/2023/phantoms/Michael-Overton-Brown-Embodiment1-9-Square.webp",
          //   "/images/projects/2023/phantoms/Michael-Overton-Brown-Embodiment1-2.webp",
          // ]}
        >
          Examines the horror and beauty of having a physical existence.
        </ProjectPage>
      </Content>

      <Footer />
    </AppWrapper>
  );
}
