import "./App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GanekNav from "./Nav";
import Footer from "./Footer";
import { ContentHeader, Content, AppWrapper } from "./layout/Layout";
import { Highlight } from "./Utils";
import { useEffect } from "react";
import { DescriptionBlock } from "./layout/Layout";

export default function Proposals() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <AppWrapper>
      <Content>
        <GanekNav />

        <ContentHeader>PROPOSALS</ContentHeader>

        <Container
          className="p-3"
          style={{ color: "white", pointerEvents: "auto" }}
        >
          <Row>
            <Col>
              <DescriptionBlock>
                <Highlight>
                  We seek proposals for projects in the realm of virtual
                  reality, augmented reality, XR, and immersive experiences
                </Highlight>
                . Projects are limited to students currently attending the
                University of Southern California.
              </DescriptionBlock>
            </Col>
          </Row>
        </Container>

        {/* <div className="mt-4" style={{ borderBottom: "solid white" }} /> */}

        <Container
          className="mt-3 p-3"
          style={{ color: "white", pointerEvents: "auto" }}
        >
          <Row>
            <Col>
              <h3>
                <Highlight>PROPOSAL GUIDELINES</Highlight>
              </h3>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4>
                Proposals should be submitted as .pdf and adhere to the
                following conventions.
              </h4>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <h5>
                <Highlight>TITLE PAGE</Highlight>
              </h5>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <h5>
                This page should have the title of your proposal, all the names
                of the students involved, and the date of submission.
              </h5>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <h5>
                <Highlight>SUMMARY PAGE</Highlight>
              </h5>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <h5>Logline: Describe your project in two or three sentences.</h5>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <h5>
                Summary: Two to three paragraphs that provide a deeper
                explanation of your project.
              </h5>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <h5>
                Experience Type: Virtual reality, augmented reality, XR,
                immersive.
              </h5>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <h5>
                <Highlight>CONCEPT IMAGES</Highlight>
              </h5>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <h5>
                One or two pages of concept imagery that is reflective of your
                visual ideas. These can be images from other projects that are
                inspiring. The Ganek Immersive Studio strongly supports the use
                of A.I. tools in image creation and we encourage the use of
                tools like Stable Diffusion, ControlNet, Mid Journey, and
                Dall-e.
              </h5>
            </Col>
          </Row>
        </Container>

        <Container
          className="mt-3 p-3"
          style={{ color: "white", pointerEvents: "auto" }}
        >
          <Row>
            <Col>
              <h3>
                <Highlight>PROPOSAL ACCEPTANCE</Highlight>
              </h3>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4>
                Ganek Immersive Studio accepts six to eight projects to produce
                annually. Projects are chosen on the strength of their concept,
                combined with the feasibility of their production. Ganek
                Immersive Studio will actively work with students to help
                develop and refine students’ proposals, as well as offer
                guidance on production techniques.
              </h4>
            </Col>
          </Row>
        </Container>
      </Content>

      <Footer />
    </AppWrapper>
  );
}
